import React, { FC, useRef, useState, useEffect, Fragment } from "react";
import { NuitType, GeoapifyType, MonnaieType } from "data/types";
import { currencyIcons } from '_config/constantes';
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import EvenementBtnsModif from "components/EvenementBtnsModif";
import AutoCompleteList from "components/AutocompleteList";
import { voyageService } from "../../_services/voyage.service";
import SelectPaiementHebergement from "shared/SelectPaiementHebergement/SelectPaiementHebergement";
import DateInput from "shared/DateInput/DateInput";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import {apiKeyGeoapify} from '_config/constantes'; 
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';


export interface CardNuitModifProps {
  className?: string;
  author: NuitType;
  index: string;
  id_pays: string;
  idVoyage: string;
  etatVoyage: number;
  onDisplayElementChange?: (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement:string) => void;
  idJournee: number;
  onLienClick?: (typeEvenement: string, idEvenement: number) => void;
  onBlocNotesClick: (typeEvenement: string, idEvenement: number) => void;
  onCoordClick: (typeEvenement: string, idEvenement: number) => void;
  onConfirmClick: (typeEvenement: string, idEvenement: number) => void;
  onCopierClick: (idEvenement: number) => void;
  onRechercheClick: (typeEvenement: string, idEvenement: number) => void;
  isMap?: boolean;
  onLocalisationClick?: (type: String) => void;
  onPlacementClick?: (type: String) => void;
  selected?: boolean;
  monnaie: string;
}

const CardNuitModif: FC<CardNuitModifProps> = ({
  className = "",
  author,
  index,
  id_pays,
  idVoyage,
  onDisplayElementChange,
  idJournee,
  etatVoyage,
  onLienClick,
  onBlocNotesClick,
  onCoordClick,
  onConfirmClick,
  onCopierClick,
  onRechercheClick,
  isMap,
  onLocalisationClick,
  onPlacementClick,
  selected,
  monnaie
}) => {
  const { t } = useTranslation();

  const { displayName, avatar } = author;
  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [editedDisplayNameHeb, setEditedDisplayNameHeb] = useState("");
  const [editedDisplayPrix, setEditedDisplayPrix] = useState("");
  const [editedDisplayAcompte, setEditedDisplayAcompte] = useState("");
  const [suggestions, setSuggestions] = useState<GeoapifyType[]>([]);
  const [suggestionsHeb, setSuggestionsHeb] = useState<GeoapifyType[]>([]);
  const [isMouseOver, setIsMouseOver] = useState(false); // État pour suivre si la souris est sur la div
  const cardNuitModifRef = useRef<HTMLDivElement>(null); // Référence pour la div principale
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalHebOpen, setIsModalHebOpen] = useState(false);
  const [lang, setLang] = useState<string>("fr");
  const [listePays, setListePays] = useState<string>("");
  const [oldDisplayName, setOldDisplayName] = useState("");
  const [oldDisplayNameHeb, setOldDisplayNameHeb] = useState("");
  const [oldDisplayPrix, setOldDisplayPrix] = useState("");
  const [oldDisplayAcompte, setOldDisplayAcompte] = useState("");
  const [oldPaiement, setOldPaiement] = useState("");
  const [selectedPaiement, setSelectedPaiement] = useState("");
  const [datePaiement, setDatePaiement] = useState<Date | null>(null);
  const [editedInvisible1, setEditedInvisible1] = useState("");
  const [editedPhoto, setEditedPhoto] = useState("");
  const [editedPhotoLien, setEditedPhotoLien] = useState("");
  const [isModalPhotoOpen, setIsModalPhotoOpen] = useState(false);

  const [editedLatitude1, setEditedLatitude1] = useState("");
  const [editedLongitude1, setEditedLongitude1] = useState("");
  const [editedLatitude2, setEditedLatitude2] = useState("");
  const [editedLongitude2, setEditedLongitude2] = useState("");

  const [isLibelleSaisi, setIsLibelleSaisi] = useState(false);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const CurrencyIcon = currencyIcons[monnaie as MonnaieType];

  let navigate=useNavigate();

  const suggestionSelectedRef = useRef(false);
  const suggestionHebSelectedRef = useRef(false);

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setIsModalOpen(false);
    setIsModalHebOpen(false);
  };

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };
  
  const handleFocusPrix = (event: React.FocusEvent<HTMLInputElement>) => {
    setOldDisplayPrix(event.target.value);
  };

  const handleFocusAcompte = (event: React.FocusEvent<HTMLInputElement>) => {
    setOldDisplayAcompte(event.target.value);
  };

  const handleFocusVille = () => {
    suggestionSelectedRef.current = false;
    setOldDisplayName(editedDisplayName);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (editedDisplayName !== "" && editedDisplayName.length >2 ) {
      const id = setTimeout(() => {
        fetchSuggestions(editedDisplayName, '');
      }, 600);

      setTimeoutId(id);
    }
  };

  const handleFocusHeb = () => {
    suggestionHebSelectedRef.current = false;
    setOldDisplayNameHeb(editedDisplayNameHeb);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    
      if (editedDisplayNameHeb !== "" && editedDisplayNameHeb.length >2 ) {
        const id = setTimeout(() => {
          fetchSuggestions(editedDisplayNameHeb, 'heb');
        }, 600);
  
        setTimeoutId(id);
      }
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayName(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {
      const id = setTimeout(() => {
        fetchSuggestions(event.target.value, '');
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestions([]);
    }
  };
  
  const handleDisplayPrixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayPrix(event.target.value);
  }

  const handleDisplayAcompteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayAcompte(event.target.value);
  }

  const handleDisplayNameHebChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayNameHeb(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {
      const id = setTimeout(() => {
        fetchSuggestions(event.target.value, 'heb');
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestionsHeb([]);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);


  const handleSelectPaiement = (valeur: string) => {

    if(valeur !== oldPaiement)
    {
      setSelectedPaiement(valeur);

      if(valeur !== "acompte")
      {
        setEditedDisplayAcompte("");
        setOldDisplayAcompte("");
      }

      if(valeur !== "prelev")
      {
        setDatePaiement(null);
      }

      //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: "mode_paiement_hebergement",
      contenu: valeur,
      conservation: false
    })
    .then((response: any) => {
    
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });

    if(onDisplayElementChange)
    {
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], valeur, 'paiement');
    }

    setOldPaiement(valeur);
  }
};



  const onPlaceChangedVille = (place: GeoapifyType) => {

      var latitude = place.properties.lat;
      var longitude = place.properties.lon; 

      var libelleVille="";

      if(place.properties.name !== undefined && place.properties.name !== null)
      {
        libelleVille=place.properties.name;
      }
      else if(place.properties.city !== undefined && place.properties.city !== null)
      {
        libelleVille=place.properties.city;
      }
      else if(place.properties.county !== undefined && place.properties.county !== null)
      {
        libelleVille=place.properties.county;
      }
      else if(place.properties.state !== undefined && place.properties.state !== null)
      {
        libelleVille=place.properties.state;
      }
      else if(place.properties.country !== undefined && place.properties.country !== null)
      {
        libelleVille=place.properties.country;
      }
      else
      {
        libelleVille=place.properties.formatted;
      }

  var pays="";
  if(place.properties.country_code !== null)
  {
    if(place.properties.country_code === 'gb')
    {
      if(place.properties.state !== null)
      {  
        switch (place.properties.state) {
                      case 'Angleterre':
                        pays = 'ww';
                        break;
                      case 'Écosse':
                        pays = 'xx';
                        break;
                      case 'Irlande du Nord':
                        pays = 'yy';
                        break;
                      case 'Pays de Galles':
                        pays = 'zz';
                        break;
                      case 'England':
                        pays = 'ww';
                        break;
                      case 'Scotland':
                        pays = 'xx';
                        break;
                      case 'Northern Ireland':
                        pays = 'yy';
                        break;
                      case 'Wales':
                        pays = 'zz';
                        break;
                      default:
                        pays=place.properties.country_code;
                  }
      }
      else
      {
        pays=place.properties.country_code;
      }
    }
    else if(place.properties.country_code === 'fr')
    {
      if(place.properties.state !== null)
      {
                
        switch (place.properties.state) {
            case 'Guadeloupe':
              pays = 'gp';
              break;
            case 'Martinique':
              pays = 'mq';
              break;
            case 'Réunion':
              pays = 're';
              break;
            case 'Mayotte':
              pays = 'yt';
              break;
            case 'Guyane':
                pays = 'gf';
                break;
            case 'Guyane Française':
              pays = 'gf';
              break;
            case 'Polynésie Française':
              pays = 'pf';
              break;
            case 'Terres Australes Françaises':
              pays = 'tf';
              break;
            case 'Saint-Pierre-et-Miquelon':
              pays = 'pm';
              break;
            case 'Wallis et Futuna':
              pays = 'wf';
              break;
            default:
            pays=place.properties.country_code;
          }
      }
      else
      {
        pays=place.properties.country_code;
      }
    }
    else
    {
      pays=place.properties.country_code;
    }
  }

      setEditedDisplayName(libelleVille);

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "libelle_ville",
        contenu: libelleVille,
        colonne1: 'lat_ville',
        colonne2: 'long_ville',
        contenu1: latitude,
        contenu2: longitude,
        placeId: '',
        codePays: pays
      })
      .then((response: any) => {
        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], libelleVille, 'libelle1');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], pays, 'pays');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], response.data.nomPays, 'nom_pays');
           setEditedLatitude1(latitude);
          setEditedLongitude1(longitude);
        }
  
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
        
  };

  const onPlaceChangedHeb = (place: GeoapifyType) => {

    var latitude = place.properties.lat;
    var longitude = place.properties.lon; 

    var libelleHeb="";

    if(place.properties.name !== undefined && place.properties.name !== null)
    {
      libelleHeb=place.properties.name;
    }
    else if(place.properties.city !== undefined && place.properties.city !== null)
    {
      libelleHeb=place.properties.city;
    }
    else if(place.properties.state !== undefined && place.properties.state !== null)
    {
      libelleHeb=place.properties.state;
    }
    else if(place.properties.country !== undefined && place.properties.country !== null)
    {
      libelleHeb=place.properties.country;
    }
    else
    {
      libelleHeb=place.properties.formatted;
    }

    setEditedDisplayNameHeb(libelleHeb);
    if(onDisplayElementChange)
    {
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], libelleHeb, 'libelle2');

      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat2');
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi2');

      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], place.properties.place_id, 'place');
      
      setEditedLatitude2(latitude);
      setEditedLongitude2(longitude);
    }


    //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: "nom_hebergement",
      contenu: libelleHeb,
      colonne1: 'lat_hebergement',
      colonne2: 'long_hebergement',
      contenu1: latitude,
      contenu2: longitude,
      placeId: place.properties.place_id,
    })
    .then((response: any) => {

    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
      
};

  const fetchSuggestions = async (value: string, type: string) => {

    const limit = 7; // Limite de suggestions

    var filter=listePays;

    var complementUrl = `&filter=countrycode:${filter}&bias=countrycode:${listePays}`;
    
    if(listePays.length === 0)
    {
      complementUrl = "";
    }

    if(type === 'heb')
    {
      complementUrl+='&type=amenity';
    }

    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=${lang}&limit=${limit}&apiKey=${apiKeyGeoapify}${complementUrl}`
      );

      if (response.ok) {

        const data = await response.json();

        if(type === '')
        {
          setSuggestions(data.features);
          setIsModalOpen(true);
        }
        else
        {
          setSuggestionsHeb(data.features);
          setIsModalHebOpen(true);
        }
      }
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };

  const handleSelectSuggestion = (suggestion: GeoapifyType) => {
    suggestionSelectedRef.current = true;
    onPlaceChangedVille(suggestion);
    setSuggestions([]);
  };
  
  const handleSelectSuggestionHeb = (suggestion: GeoapifyType) => {
   
    suggestionHebSelectedRef.current = true;
    onPlaceChangedHeb(suggestion);
    setSuggestionsHeb([]);
  };

  useEffect(() => {
    
  var alphabetDiff=false;

  if(id_pays !== null)
  {
        var tab=id_pays.split('_');

        for(var i= 0; i < tab.length; i++)
        {
            if(tab[i].toLowerCase() === 'ww' || tab[i].toLowerCase() === 'xx' || tab[i].toLowerCase() === 'yy' || tab[i].toLowerCase() === 'zz')
            {
                tab[i]='gb';
            }
            else if(tab[i].toLowerCase() === 'yt' || tab[i].toLowerCase() === 'gf' || tab[i].toLowerCase() === 'pf' || tab[i].toLowerCase() === 'tf' 
                 || tab[i].toLowerCase() === 'gp' || tab[i].toLowerCase() === 'mq' || tab[i].toLowerCase() === 're' || tab[i].toLowerCase() === 'pm'
                 || tab[i].toLowerCase() === 'wf')
            {
                tab[i]='fr';
            }
            else if(tab[i].toLowerCase() === 'as' || tab[i].toLowerCase() === 'mp' || tab[i].toLowerCase() === 'vi')
            {
                tab[i]='us';
            }
            else if(tab[i].toLowerCase() === 'bv' || tab[i].toLowerCase() === 'sj')
            {
                tab[i]='no';
            }
            else if(tab[i].toLowerCase() === 'cc' || tab[i].toLowerCase() === 'hm' || tab[i].toLowerCase() === 'nf')
            {
                tab[i]='au';
            }
        }

      var listePaysAlphabetDiff=['hk','af','am','bh','bd','by','bt','mm','bg','kh','cn','cy','eg','er','ge','gr','in','ir','iq','il','jp','jo','kz','kw','kg','la','ly','mk','mr','mn','ma','np','kp','om','pk','qa','ru','sa','cs','kr','lk','sd','sy','tj','tw','th','tn','ua','ye','ae','ps','mo','rs'];
     
      switch (tab.length) {
          case 1:
            setListePays(tab[0].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 2:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 3:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 4:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 5:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase()+','+tab[4].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[4].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          default:
            setListePays("");
            alphabetDiff=true;
        }
        if(listePays.includes("undefined"))
        {
          setListePays("");
          alphabetDiff=true;
        }
    }

    if(alphabetDiff)
    {
      setLang("en");
    }
    else
    {
      setLang("fr");
    }
}, [id_pays]);

const handleBlurVille = () => {

  setTimeout(() => {
    const trimmedDisplayName = editedDisplayName.trim();

    if ( trimmedDisplayName !== oldDisplayName && !suggestionSelectedRef.current ) {

      if(onDisplayElementChange)
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], editedDisplayName, 'libelle1');
      } 

      var conservation=false;

      if(editedDisplayName !== "" && oldDisplayName !== "")
      {
        const isConfirmed = window.confirm(
          'Vous avez modifié le nom de la ville sans avoir sélectionné un élement de la liste déroulante, nous n\'avons donc pas pu récupérer sa localisation.\n\nSouhaitez-vous conserver les coordoonnées GPS éventuellement déjà enregistrées pour cet élément ?\n- "OK" pour les conserver\n- "Annuler" pour les effacer'
        );
      
        // Vérifiez si l'utilisateur a confirmé
        if (isConfirmed) {
          conservation=true;
        }
        else
        {
          if(onDisplayElementChange)
          {
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'pays');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'nom_pays');
           
            setEditedLatitude1("");
            setEditedLongitude1("");
          }
        }
      }
      else
      {
        if(onDisplayElementChange)
          {
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'pays');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'nom_pays');
           
            setEditedLatitude1("");
            setEditedLongitude1("");
          }
      }


      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "libelle_ville",
        contenu: editedDisplayName,
        colonne1: '',
        colonne2: '',
        contenu1: '',
        contenu2: '',
        placeId: '',
        conservation
      })
      .then((response: any) => {

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

      // Effacez la liste de suggestions après l'enregistrement
      setSuggestions([]);
    
    }
  }, 200);
};

const handleBlurHeb = () => {

  setTimeout(() => {

    const trimmedDisplayNameHeb = editedDisplayNameHeb.trim();

    if ( trimmedDisplayNameHeb !== oldDisplayNameHeb && !suggestionHebSelectedRef.current ) {

      if(onDisplayElementChange)
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], editedDisplayNameHeb, 'libelle2');
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'place');
      } 

      var conservation=false;
      if(editedDisplayNameHeb !== "" && oldDisplayNameHeb !== "")
      {
        const isConfirmed = window.confirm(
          'Vous avez modifié le nom de l\'hébergement sans avoir sélectionné un élement de la liste déroulante, nous n\'avons donc pas pu récupérer sa localisation.\n\nSouhaitez-vous conserver les coordoonnées GPS éventuellement déjà enregistrées pour cet élément ?\n- "OK" pour les conserver\n- "Annuler" pour les effacer'
        );
      
        // Vérifiez si l'utilisateur a confirmé
        if (isConfirmed) {
          conservation=true;
        }
        else
        {
          if(onDisplayElementChange)
          {    
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat2');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi2');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'place');
      
            setEditedLatitude2("");
            setEditedLongitude2("");
          }
        }
      }
      else
      {
        if(onDisplayElementChange)
          {    
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat2');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi2');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'place');
      
            setEditedLatitude2("");
            setEditedLongitude2("");
          }
      }

      
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "nom_hebergement",
        contenu: editedDisplayNameHeb,
        colonne1: '',
        colonne2: '',
        contenu1: '',
        contenu2: '',
        placeId: '',
        conservation
      })
      .then((response: any) => {

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

      // Effacez la liste de suggestions après l'enregistrement
      setSuggestionsHeb([]);
    }
  }, 200);
};

const handleBlurPrix = (event: React.FocusEvent<HTMLInputElement>, colonne: string) => {

  var newValue = event.target.value.trim();  

  newValue = newValue.replace(",", ".")
  event.target.value=newValue;

  if((colonne === "prix_hebergement" && newValue !== oldDisplayPrix) || (colonne === "acompte_hebergement" && newValue !== oldDisplayAcompte))
  {

    try
    {
      if(isNaN(Number(newValue)) || newValue.lastIndexOf(" ") !== -1 )
      {
        alert('Cette zone ne peut contenir que des chiffres.');
        event.target.value='';
        event.target.focus();
        return false;
      }
      else if(Number(newValue) > 99999999.99)
      {
        alert('Le prix ne peut pas être supérieur à 99999999.99');
        event.target.value='';
        event.target.focus();
        return false;
      }

      if(onDisplayElementChange)
    {  
      if(colonne.startsWith("prix"))
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'prix');
      }
      else
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'acompte_hebergement');
      }
    }


      if(colonne === "prix_hebergement")
      {
        setEditedDisplayPrix(newValue);

        if(newValue === "" || Number(newValue) === 0)
        {
          setEditedDisplayAcompte("");
          setOldDisplayAcompte("");
          setSelectedPaiement("");
          setOldPaiement("");
          setDatePaiement(null);

          if(onDisplayElementChange)
          {
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'paiement');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'acompte_paiement');
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'date_paiement');
          }
        }
      }
      else
      {
        setEditedDisplayAcompte(Number(newValue).toString());
      }
    }
    catch(error)
    {
      alert('Cette zone ne peut contenir que des chiffres.');
      event.target.value='';
      event.target.focus();
      return false;
    }

    //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: colonne,
      contenu: Number(newValue).toString(),
      conservation: false
    })
    .then((response: any) => {

    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
  }
};

const convertStringToDate = (dateString: string): Date | null => {

  if (!dateString) return null;

  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day); // Month is 0-indexed
};

const handleSelectDate = (dateSelected: Date | null) => {

  var dateSelectedString="";

  if(dateSelected !== null)
  {
    dateSelectedString=dateSelected.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  if(onDisplayElementChange)
  {  
    onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], dateSelectedString, 'date_paiement_hebergement');
  }

  setDatePaiement(dateSelected);

    //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: "date_paiement_hebergement",
      contenu: dateSelectedString,
      conservation: false
    })
    .then((response: any) => {

    })
    .catch((error: any) =>
    {
      console.log("error", error);
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
};

const handlePhotoDelete = async () => {
  
  const fileName = editedPhotoLien.split("/photo/")[1];

  // Handle file upload to the server or save it locally
  try {

    voyageService.supprimerPhoto(idVoyage, fileName)
  .then((response: any) => {

    setEditedPhoto("");
    setEditedPhotoLien("");
    if(onDisplayElementChange)
    {
      // Appeler la fonction de rappel pour propager les changements
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'image');
    }

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de la suppression de l'image, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de la suppression de l'image");
    }
    console.error('Error maj image:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
};

const handlePhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
const fileInput = event.target;
const file = fileInput.files?.[0];

let imageURL: string | undefined;

if (file) {
  // Check file type
  if (!/\/(jpeg|jpg)$/.test(file.type)) {
    alert("Veuillez sélectionner un fichier JPEG ou JPG.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Check file size
  const maxSize = 8 * 1024 * 1024; // 7MB
  if (file.size > maxSize) {
    alert("La taille du fichier ne doit pas dépasser 8 Mo.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Assuming you have some way to get the user's identifiant
  const fileName = `photo_${index?.split('-')[0]}_${index?.split('-')[1]}.${file.type.split("/")[1]}`;

  // Handle file upload to the server or save it locally
  try {
    // Example using the Fetch API to upload the file (modify as needed)
    const formData = new FormData();
    formData.append("photo", file, fileName);

    voyageService.uploadPhoto(idVoyage, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  .then((response: any) => {

    setEditedPhotoLien(response.data.photoInfo.url);

      Axios.get(response.data.photoInfo.url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        setEditedPhoto(imageURL);
      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));

    if(onDisplayElementChange)
    {
      // Appeler la fonction de rappel pour propager les changements
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], response.data.photoInfo.url, 'image');
    }

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement de l'image, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de l'enregistrement de l'image");
    }
    console.error('Error maj image:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
}
return () => {
  if (imageURL) {
    URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
  }
};
};

const openModalPhoto = () => {
setIsModalPhotoOpen(true);
};

const closeModalPhoto = () => {
setIsModalPhotoOpen(false);
};

const handlePhotoClick = async () => {
  if(etatVoyage !== 0)
  {
    openModalPhoto();
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour enregistrer une photo.");
  }
};

  useEffect(() => {
    const handleContainerMouseLeave = () => {
      setIsMouseOver(false);
    };

    const container = cardNuitModifRef.current;
    if (container) {
      container.addEventListener("mouseleave", handleContainerMouseLeave);
      return () => {
        container.removeEventListener("mouseleave", handleContainerMouseLeave);
      };
    }
  }, [cardNuitModifRef]);

  useEffect(() => {
  }, [isModalOpen]);

  useEffect(() => {
  }, [isModalHebOpen]);

  useEffect(() => {
    setEditedDisplayName(author.displayName || "");
    setEditedDisplayNameHeb(author.libelle2 || "");
    setEditedDisplayPrix(author.prix === '0.00' ? "" : author.prix);
    setEditedDisplayAcompte(author.acompte_hebergement === '0.00' ? "" : author.acompte_hebergement);
    setSelectedPaiement(author.paiement || "");
    setOldPaiement(selectedPaiement);
    setDatePaiement(convertStringToDate(author.date_paiement_hebergement));
    setEditedInvisible1(author.invisible1 || "");
    setEditedPhotoLien(author.avatar || "");
    
    setEditedLatitude1(author.lat || "");
    setEditedLongitude1(author.longi || "");
    setEditedLatitude2(author.lat2 || "");
    setEditedLongitude2(author.longi2 || "");
    
    let imageURL: string | undefined;

    if(author.avatar !== "")
    {

      Axios.get(author.avatar, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        setEditedPhoto(imageURL);

      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    }
    else
    {
      setEditedPhoto("");
    }

    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };

  }, [author.invisible1, author.displayName, author.libelle2, author.lat, author.longi, author.lat2, author.longi2, author.acompte_hebergement, author.prix, author.date_paiement_hebergement, author.paiement, author.avatar]);
  
  useEffect(() => {
    if(editedDisplayNameHeb !== "" || editedDisplayName !== "")
    {
      setIsLibelleSaisi(true);
    }
    else
    {
      setIsLibelleSaisi(false);
    }
  
  }, [editedDisplayNameHeb, editedDisplayName]);


  return (
    <>
    { editedInvisible1 !== 'O' && (
    <div

      className={`${selected ? "selectedCard" : ""} nc-CardNuitModif relative flex flex-col items-center justify-center text-center mt-2 px-3 py-5 pb-2 sm:px-4 sm:pb-3 sm:pt-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardNuitModif"
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
        <Badge
        taille="text-xxs py-1"
          className="absolute left-1 top-3"
          color="blue"
          name={t('nuit')}
        />
        <EvenementBtnsModif isMap={isMap} libelleSaisi={isLibelleSaisi} onCopierClick={onCopierClick} onLienClick={onLienClick} onBlocNotesClick={onBlocNotesClick} onCoordClick={onCoordClick} onPhotoClick={handlePhotoClick} onConfirmClick={onConfirmClick} onRechercheClick={onRechercheClick} index={index}/>
     {avatar !== '' && (
      <Avatar
      sizeClass={`w-${isMap ? '10' : '20'} h-${isMap ? '10' : '20'} text-2xl`}
        radius="rounded-full"
        imgUrl={`${editedPhoto}`}
        userName={displayName}
      />
      )}
      <div className="flex items-center justify-center w-full mt-3">
      <div className={`text-base font-medium w-full`}>
          <input
            type="text"
            value={editedDisplayName}
            onChange={handleDisplayNameChange}
            className="champAutocomplete"
            placeholder={t('nomVille')}
            maxLength={50}
            onBlur={handleBlurVille}
            onFocus={handleFocusVille}
          />
           {/* Afficher la liste de suggestions en tant que pop-up */}
          {isModalOpen && suggestions.length > 0 && (
            <AutoCompleteList
              suggestions={suggestions}
              onSelect={handleSelectSuggestion}
            />
          )}
      </div>
      { isMap && editedDisplayName !== "" && editedLatitude1 !== "" && editedLongitude1 !== "" && (editedLatitude2 === "" || editedLongitude2 === "") && (
          <div className="mt-2">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLoc"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("ville")}
                              ></i>
                              <Tooltip id="myTooltipLoc" style={{ zIndex: 9999 }}></Tooltip>
          </div>
      )}
      {isMap && editedDisplayName !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                            data-tooltip-content={t('cliquezPlacement')}
                            data-tooltip-place="top"
                            onClick={() => onPlacementClick && onPlacementClick("ville")}
                            
                           ></i>
                           <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      {!isMap && editedDisplayName !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                            data-tooltip-content={t('coordonneesGPSAbsentes')}
                            data-tooltip-place="top"
                            
                           ></i>
                           <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      </div>
      
      <div className="flex items-center justify-center w-full mt-1.5">
      <div className={`text-base font-medium w-full`}>
         <input
            type="text"
            value={editedDisplayNameHeb}
            onChange={handleDisplayNameHebChange}
            className="champAutocomplete"
            placeholder={t('nomHebergement')}
            maxLength={50}
            onBlur={handleBlurHeb}
            onFocus={handleFocusHeb}
          />
           {/* Afficher la liste de suggestions en tant que pop-up */}
          {isModalHebOpen && suggestionsHeb.length > 0 && (
            <AutoCompleteList
              suggestions={suggestionsHeb}
              onSelect={handleSelectSuggestionHeb}
            />
          )}
        </div>
        { isMap && editedDisplayNameHeb !== "" && editedLatitude2 !== "" && editedLongitude2 !== "" && (
          <div className="mt-2 ">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLocArr"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("hebergement")}
                              ></i>
                              <Tooltip id="myTooltipLocArr" style={{ zIndex: 9999 }}></Tooltip>
          </div>
          )}
      { isMap && editedDisplayNameHeb !== "" && (editedLatitude2 === "" || editedLongitude2 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip2"
                            data-tooltip-content={t('cliquezPlacement')}
                            data-tooltip-place="top"
                            onClick={() => onPlacementClick && onPlacementClick("hebergement")}
                            
                           ></i>
                           <Tooltip id="myTooltip2" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      { !isMap && editedDisplayNameHeb !== "" && (editedLatitude2 === "" || editedLongitude2 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip2"
                            data-tooltip-content={t('coordonneesGPSAbsentes')}
                            data-tooltip-place="top"
                            
                           ></i>
                           <Tooltip id="myTooltip2" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      </div>
      {!isMap && (
        <div className="py-2 px-3 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
          <div><input
            type="text"
            value={editedDisplayPrix}
            onChange={handleDisplayPrixChange}
            className="text-xs font-medium text-center"
            style={{ maxWidth: '100px' }} 
            maxLength={11}
            onBlur={(event) => handleBlurPrix(event, "prix_hebergement")}
            placeholder={t('prixNuit')}
            onFocus={handleFocusPrix}
          />
          </div>
          <div className="text-left">
          {CurrencyIcon}
          </div>
          <div>
          { editedDisplayPrix !== '' && editedDisplayPrix !== "0" && editedDisplayPrix !== "0.0" && editedDisplayPrix !== "0.00" && (
          <SelectPaiementHebergement
            value={selectedPaiement}
            onChange={(event) => handleSelectPaiement(event.target.value)}
            className="pointeur"
          >
              <option value=""></option>
              <option value="paye">{t('paye')}</option>
              <option value="sur_place">Sur place</option>
              <option value="prelev">Prélèvement</option>
              <option value="acompte">Acompte</option>
           </SelectPaiementHebergement>
          )}
         </div>
         
        </div>
      )}
        {!isMap && selectedPaiement === "acompte" && editedDisplayPrix !== '' && editedDisplayPrix !== "0" && editedDisplayPrix !== "0.0" && editedDisplayPrix !== "0.00" && (
        <div className="py-2 px-3 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <div><input
            type="text"
            value={editedDisplayAcompte}
            onChange={handleDisplayAcompteChange}
            className="text-xs font-medium text-center"
            style={{ maxWidth: '100px' }} 
            maxLength={11}
            onBlur={(event) => handleBlurPrix(event, "acompte_hebergement")}
            placeholder="Montant acompte"
            onFocus={handleFocusAcompte}
          />
          </div>
          <div className="text-left">
          {CurrencyIcon}
          </div>
        </div>
        )}
        { !isMap && selectedPaiement === "prelev" && editedDisplayPrix !== '' && editedDisplayPrix !== "0" && editedDisplayPrix !== "0.0" && editedDisplayPrix !== "0.00" && (
            <div className="py-2 px-3 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center">
              <DateInput onSelectDate={handleSelectDate} date={datePaiement} libelle={"Date Prélèvement"}/>
            </div>
          )}
          
    </div>
    )}

    
{isModalPhotoOpen && (
        <Transition appear show={isModalPhotoOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalPhoto}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification d'une Photo
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPhoto} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                        
                      { !editedPhoto && (
                      <>
                        <form encType="multipart/form-data">
                        <input
                          name='photo'
                          type="file"
                          className="cursor-pointer"
                          accept="image/jpeg, image/jpg"
                          onChange={handlePhotoChange}
                        />
                        </form>

                        <div className="text-xxs text-center mt-3">
                          .jpeg et jpg acceptés, 8 Mo max
                        </div>
                      </>
                        )}

                        {editedPhoto && (
                          <>
                                <img
                                  //className={`pointeur absolute inset-0 w-full h-full object-cover ${radius}`}
                                  src={`${editedPhoto}`}
                                  alt={displayName}
                                  //onClick={handleAvatarClick}
                                />
                                <ButtonPrimary className="mt-3 mb-5" onClick={handlePhotoDelete}
                                >Supprimer</ButtonPrimary>
                          </>
                        )}
                        
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}
    </>
  );
};

export default CardNuitModif;
