import React, { FC, useState, useEffect, useRef, Fragment } from "react";
import { LieuType, GeoapifyType, MonnaieType } from "data/types";
import { currencyIcons } from '_config/constantes';
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import EvenementBtnsModif from "components/EvenementBtnsModif";
import AutoCompleteList from "components/AutocompleteList";
import ModalPhotoModif from "components/ModalPhotoModif";
import Textarea from "shared/Textarea/Textarea";
import CheckboxSmall from "shared/CheckboxSmall/CheckboxSmall";
import { voyageService } from "../../_services/voyage.service";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import {apiKeyGeoapify} from '_config/constantes'; 
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';


export interface CardLieuModifProps {
  className?: string;
  author: LieuType;
  index: string;
  id: string;
  onSuppressionClick?: (typeEvenement: string, idEvenement: number) => void;
  onLienClick?: (typeEvenement: string, idEvenement: number) => void;
  id_pays: string;
  idVoyage: string;
  etatVoyage: number;
  onDisplayElementChange?: (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement:string) => void;
  idJournee: number;
  onBlocNotesClick: (typeEvenement: string, idEvenement: number) => void;
  onCoordClick: (typeEvenement: string, idEvenement: number) => void;
  onConfirmClick: (typeEvenement: string, idEvenement: number) => void;
  onPOIClick?: (idEvenement: number) => void;
  onRechercheClick: (typeEvenement: string, idEvenement: number) => void;
  isMap?: boolean;
  onLocalisationClick?: () => void;
  onPlacementClick?: () => void;
  selected?: boolean;
  monnaie: string;
}

const CardLieuModif: FC<CardLieuModifProps> = ({
  className = "",
  author,
  index,
  id,
  onSuppressionClick,
  onLienClick,
  id_pays,
  idVoyage,
  etatVoyage,
  onDisplayElementChange,
  idJournee,
  onBlocNotesClick,
  onCoordClick,
  onConfirmClick,
  onPOIClick,
  onRechercheClick,
  isMap,
  onLocalisationClick,
  onPlacementClick,
  selected,
  monnaie
}) => {
  const { t } = useTranslation();

  const { displayName, libelle2, type, paiement, avatar } = author;

  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [editedActivites, setEditedActivites] = useState("");
  const [editedDisplayPrix, setEditedDisplayPrix] = useState("");
  const [editedPaiement, setEditedPaiement] = useState("");
  const [suggestions, setSuggestions] = useState<GeoapifyType[]>([]);
  const [isMouseOver, setIsMouseOver] = useState(false); // État pour suivre si la souris est sur la div
  const cardLieuModifRef = useRef<HTMLDivElement>(null); // Référence pour la div principale
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lang, setLang] = useState<string>("fr");
  const [listePays, setListePays] = useState<string>("");
  const [oldDisplayName, setOldDisplayName] = useState("");
  const [oldDisplayTextarea, setOldDisplayTextarea] = useState("");
  const [oldDisplayPrix, setOldDisplayPrix] = useState("");
  const [editedPhoto, setEditedPhoto] = useState("");
  const [editedPhotoLien, setEditedPhotoLien] = useState("");
  const [isModalPhotoOpen, setIsModalPhotoOpen] = useState(false);
  const [editedLatitude1, setEditedLatitude1] = useState("");
  const [editedLongitude1, setEditedLongitude1] = useState("");
  const [isLibelleSaisi, setIsLibelleSaisi] = useState(false);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const CurrencyIcon = currencyIcons[monnaie as MonnaieType];

  let navigate=useNavigate();

  const suggestionSelectedRef = useRef(false);


  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleFocusLieu = () => {
    suggestionSelectedRef.current = false;
    setOldDisplayName(editedDisplayName);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (editedDisplayName !== "" && editedDisplayName.length >2 ) {
      const id = setTimeout(() => {
        fetchSuggestions(editedDisplayName);
      }, 600);

      setTimeoutId(id);
    }
  };

  const handleFocusTextarea = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setOldDisplayTextarea(event.target.value);
  };

  const handleFocusPrix = (event: React.FocusEvent<HTMLInputElement>) => {
    setOldDisplayPrix(event.target.value);
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDisplayName(event.target.value);
  
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {
      const id = setTimeout(() => {
        fetchSuggestions(event.target.value);
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestions([]);
    }
  };
  
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);


  const onPlaceChangedLieu = (place: GeoapifyType) => {

      var latitude = place.properties.lat;
      var longitude = place.properties.lon; 

      var libelleLieu="";

      if(place.properties.name !== undefined && place.properties.name !== null)
      {
        libelleLieu=place.properties.name;
      }
      else if(place.properties.result_type !== undefined && place.properties.result_type !== null && place.properties.result_type === 'street')
      {
        if(place.properties.street !== undefined && place.properties.street !== null)
        {
          libelleLieu=place.properties.street;
        }
        else if(place.properties.city !== undefined && place.properties.city !== null)
        {
          libelleLieu=place.properties.city;
        }
        else if(place.properties.state !== undefined && place.properties.state !== null)
        {
          libelleLieu=place.properties.state;
        }
        else if(place.properties.country !== undefined && place.properties.country !== null)
        {
          libelleLieu=place.properties.country;
        }
        else
        {
          libelleLieu=place.properties.formatted;
        }
      }
      else if(place.properties.result_type !== undefined && place.properties.result_type !== null && place.properties.result_type === 'county')
      {
        if(place.properties.county !== undefined && place.properties.county !== null)
        {
          libelleLieu=place.properties.county;
        }
        else if(place.properties.city !== undefined && place.properties.city !== null)
        {
          libelleLieu=place.properties.city;
        }
        else if(place.properties.state !== undefined && place.properties.state !== null)
        {
          libelleLieu=place.properties.state;
        }
        else if(place.properties.country !== undefined && place.properties.country !== null)
        {
          libelleLieu=place.properties.country;
        }
        else
        {
          libelleLieu=place.properties.formatted;
        }
      }
      else
      {
        if(place.properties.city !== undefined && place.properties.city !== null)
        {
          libelleLieu=place.properties.city;
        }
        else if(place.properties.state !== undefined && place.properties.state !== null)
        {
          libelleLieu=place.properties.state;
        }
        else if(place.properties.country !== undefined && place.properties.country !== null)
        {
          libelleLieu=place.properties.country;
        }
        else
        {
          libelleLieu=place.properties.formatted;
        }
      }

      setEditedDisplayName(libelleLieu);
      setEditedLatitude1(latitude);
      setEditedLongitude1(longitude);
     
      if(onDisplayElementChange)
      {
        // Appeler la fonction de rappel pour propager les changements
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], libelleLieu, 'libelle1');
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat');
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi');
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], place.properties.place_id, 'place');
      
      }  

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "libelle_lieu",
        contenu: libelleLieu,
        colonne1: 'lat_lieu',
        colonne2: 'long_lieu',
        contenu1: latitude,
        contenu2: longitude,
        placeId: place.properties.place_id
      })
      .then((response: any) => {
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
        
   //enregistrerSaisie('lieu', id2, 'libelle_lieu', idBalise, latitude, longitude, null, place.properties.place_id);
};

  const fetchSuggestions = async (value: string) => {

    const limit = 7; // Limite de suggestions

    var filter=listePays;
    if(listePays.length>0 && listePays.length<14 && !listePays.includes("fr"))
    {
      filter+=",fr";
    }

    var complementUrl = `&filter=countrycode:${filter}&bias=countrycode:${listePays}`;
    
    if(listePays.length === 0)
    {
      complementUrl = "";
    }



    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=${lang}&limit=${limit}&apiKey=${apiKeyGeoapify}${complementUrl}`
      );

      if (response.ok) {
        const data = await response.json();

        console.log("data",data);

        setSuggestions(data.features);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };

  const handleSelectSuggestion = (suggestion: GeoapifyType) => {
    suggestionSelectedRef.current = true;
    console.log("suggestion",suggestion);

    onPlaceChangedLieu(suggestion);
    setSuggestions([]);
  };

  useEffect(() => {
    
  var alphabetDiff=false;

  if(id_pays !== null)
  {
        var tab=id_pays.split('_');

        for(var i= 0; i < tab.length; i++)
        {
            if(tab[i].toLowerCase() === 'ww' || tab[i].toLowerCase() === 'xx' || tab[i].toLowerCase() === 'yy' || tab[i].toLowerCase() === 'zz')
            {
                tab[i]='gb';
            }
            else if(tab[i].toLowerCase() === 'yt' || tab[i].toLowerCase() === 'gf' || tab[i].toLowerCase() === 'pf' || tab[i].toLowerCase() === 'tf' 
                 || tab[i].toLowerCase() === 'gp' || tab[i].toLowerCase() === 'mq' || tab[i].toLowerCase() === 're' || tab[i].toLowerCase() === 'pm'
                 || tab[i].toLowerCase() === 'wf')
            {
                tab[i]='fr';
            }
            else if(tab[i].toLowerCase() === 'as' || tab[i].toLowerCase() === 'mp' || tab[i].toLowerCase() === 'vi')
            {
                tab[i]='us';
            }
            else if(tab[i].toLowerCase() === 'bv' || tab[i].toLowerCase() === 'sj')
            {
                tab[i]='no';
            }
            else if(tab[i].toLowerCase() === 'cc' || tab[i].toLowerCase() === 'hm' || tab[i].toLowerCase() === 'nf')
            {
                tab[i]='au';
            }
        }

      var listePaysAlphabetDiff=['hk','af','am','bh','bd','by','bt','mm','bg','kh','cn','cy','eg','er','ge','gr','in','ir','iq','il','jp','jo','kz','kw','kg','la','ly','mk','mr','mn','ma','np','kp','om','pk','qa','ru','sa','cs','kr','lk','sd','sy','tj','tw','th','tn','ua','ye','ae','ps','mo','rs'];
     
      switch (tab.length) {
          case 1:
            setListePays(tab[0].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 2:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 3:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 4:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 5:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase()+','+tab[4].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[4].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          default:
            setListePays("");
            alphabetDiff=true;
        }
        if(listePays.includes("undefined"))
        {
          setListePays("");
          alphabetDiff=true;
        }
    }

    if(alphabetDiff)
    {
      setLang("en");
    }
    else
    {
      setLang("fr");
    }
}, [id_pays]);

const handleBlurLieu = () => {

  setTimeout(() => {
    const trimmedDisplayName = editedDisplayName.trim();

    if ( trimmedDisplayName !== oldDisplayName && !suggestionSelectedRef.current ) {

      if(onDisplayElementChange)
      {
        // Appeler la fonction de rappel pour propager les changements
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], editedDisplayName, 'libelle1');
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'place');
      }    

      var conservation=false;
      if(editedDisplayName !== "" && oldDisplayName !== "")
      {
        const isConfirmed = window.confirm(
          'Vous avez modifié le nom du lieu sans avoir sélectionné un élement de la liste déroulante, nous n\'avons donc pas pu récupérer sa localisation.\n\nSouhaitez-vous conserver les coordoonnées GPS éventuellement déjà enregistrées pour cet élément ?\n- "OK" pour les conserver\n- "Annuler" pour les effacer'
        );
      
        // Vérifiez si l'utilisateur a confirmé
        if (isConfirmed) {
          conservation=true;
        }
      }

      if(!conservation)
      {
        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'lat');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], '', 'longi');
          
        }
        setEditedLatitude1("");
        setEditedLongitude1("");
      }
      
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "libelle_lieu",
        contenu: editedDisplayName,
        colonne1: '',
        colonne2: '',
        contenu1: '',
        contenu2: '',
        placeId: '',
        conservation
      })
      .then((response: any) => {

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

      // Effacez la liste de suggestions après l'enregistrement
      setSuggestions([]);
    }
  }, 200);
};

const handleChangeCheckbox = (checked: boolean) => {

  var newValue = "";  

  if(onDisplayElementChange)
  {
    if(checked)
    {
      newValue = "paye"
    }
    // Appeler la fonction de rappel pour propager les changements
    onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'paiement');
  }  

  setEditedPaiement(newValue);

  //enregistrement de la modification
  voyageService.modifierEvenement(idVoyage, {
    typeEvenement: index?.split('-')[0],
    idEvenement: index?.split('-')[1],
    colonne: "etat_paiement_activites",
    contenu: newValue,
    conservation: false
  })
  .then((response: any) => {

  })
  .catch((error: any) =>
  {
    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de l'enregistrement de la modification de l'évenement");
    }
  });
};

const handleBlurTextarea = (event: React.FocusEvent<HTMLTextAreaElement>, colonne: string) => {

      var newValue = event.target.value;  

      if(newValue !== oldDisplayTextarea)
      {
        newValue = newValue.replaceAll('#', '%23');
        newValue = newValue.replaceAll('&', '%26');
        newValue = newValue.replaceAll('"', '');

        if(onDisplayElementChange)
        {
          // Appeler la fonction de rappel pour propager les changements
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'libelle2');
        }  

        //enregistrement de la modification
        voyageService.modifierEvenement(idVoyage, {
          typeEvenement: index?.split('-')[0],
          idEvenement: index?.split('-')[1],
          colonne: colonne,
          contenu: newValue,
          conservation: false
        })
        .then((response: any) => {

        })
        .catch((error: any) =>
        {
          if (error.response?.status === 401) {
            alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
            localStorage.removeItem('xsrfToken');
            navigate('/login');
          }
          else
          {
            alert("Erreur lors de l'enregistrement de la modification de l'évenement");
          }
        });
      }
};

const handleBlurPrix = (event: React.FocusEvent<HTMLInputElement>, colonne: string) => {

  var newValue = event.target.value;  

  newValue = newValue.replace(",", ".")
  event.target.value=newValue;

  if(newValue !== oldDisplayPrix)
  {

    try
    {
      if(isNaN(Number(newValue)) || newValue.lastIndexOf(" ") !== -1 )
      {
        alert('Cette zone ne peut contenir que des chiffres.');
        event.target.value='';
        event.target.focus();
        return false;
      }
      else if(Number(newValue) > 99999999.99)
      {
        alert('Le prix ne peut pas être supérieur à 99999999.99');
        event.target.value='';
        event.target.focus();
        return false;
      }

      setEditedDisplayPrix(newValue);

      if(onDisplayElementChange)
      {
        // Appeler la fonction de rappel pour propager les changements
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'prix');
      }    

      if (newValue === "" || Number(newValue) === 0) {
        setEditedPaiement("no");
      }

    }
    catch(error)
    {
      alert('Cette zone ne peut contenir que des chiffres.');
      event.target.value='';
      event.target.focus();
      return false;
    }

    //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: colonne,
      contenu: newValue,
      conservation: true
    })
    .then((response: any) => {

    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
  }
};

const openModalPhoto = () => {
  setIsModalPhotoOpen(true);
};

const closeModalPhoto = () => {
  setIsModalPhotoOpen(false);
};

const handlePhotoClick = async () => {
  if(etatVoyage !== 0)
  {
    openModalPhoto();
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour enregistrer une photo.");
  }
};

  useEffect(() => {
    const handleContainerMouseLeave = () => {
      setIsMouseOver(false);
    };

    const container = cardLieuModifRef.current;
    if (container) {
      container.addEventListener("mouseleave", handleContainerMouseLeave);
      return () => {
        container.removeEventListener("mouseleave", handleContainerMouseLeave);
      };
    }
  }, [cardLieuModifRef]);

  useEffect(() => {
  }, [isModalOpen]);

 useEffect(() => {
    setEditedDisplayName(author.displayName || "");
    setEditedDisplayPrix(author.prix === '0.00' ? "" : author.prix);
    setEditedPaiement(author.paiement || "");
    setEditedPhotoLien(author.avatar || "");

    let imageURL: string | undefined;

    if(author.avatar !== "")
    {

      Axios.get(author.avatar, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        setEditedPhoto(imageURL);

      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    }
    else
    {
      setEditedPhoto("");
    }
    
    setEditedLatitude1(author.lat || "");
    setEditedLongitude1(author.longi || "");

    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };
    
  }, [author.lat, author.longi]);


  useEffect(() => {
    if(editedDisplayName !== "")
    {
      setIsLibelleSaisi(true);
    }
    else
    {
      setIsLibelleSaisi(false);
    }
  
  }, [editedDisplayName]);

  useEffect(() => {
    setEditedActivites(libelle2);
  }, [libelle2]);

  const onMajPhoto = (nomPhoto: string) => {
    setEditedPhoto(nomPhoto);
  }
 
  const onMajPhotoLien = (nomPhotoLien: string) => {
    setEditedPhotoLien(nomPhotoLien);
  }

  return (
    <div
      ref={cardLieuModifRef}
      className={`${selected ? "selectedCard" : ""} nc-CardLieuModif relative flex flex-col items-center justify-center text-center mt-2 px-3 py-5 pb-2 sm:px-4 sm:pb-3 sm:pt-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardLieuModif"
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      
      <Badge
      taille="text-xxs py-1"
        className="absolute left-1 top-3"
        color={type === "" ? "gray" : "yellow"}
        name={type === "" ? t('lieu') : t('repas')}
      />
      
      <EvenementBtnsModif libelleSaisi={isLibelleSaisi} 
      onSuppressionClick={onSuppressionClick} 
      onLienClick={onLienClick} 
      onBlocNotesClick={onBlocNotesClick} 
      onCoordClick={onCoordClick} 
      onPhotoClick={handlePhotoClick} 
      onConfirmClick={onConfirmClick} 
      onPOIClick={(onPOIClick && editedLatitude1 !== null && editedLongitude1 !== "") ? onPOIClick : undefined}
      onRechercheClick={type === "" ? onRechercheClick : undefined} 
      index={index}/>

      {avatar !== "" && (
        <Avatar
          sizeClass={`w-${isMap ? '10' : '20'} h-${isMap ? '10' : '20'} text-2xl`}
          radius="rounded-full"
          imgUrl={`${editedPhoto}`}
          userName={displayName}
        />
      )}
      <div className="mt-3 w-full">
        <div className="flex items-center justify-center">
        <div className={`text-base font-medium w-full`}>
          <input
            type="text"
            value={editedDisplayName}
            onChange={handleDisplayNameChange}
            className="champAutocomplete"
            placeholder={type === "" ? t('nomVilleLieu') : t('nomRestaurantLieu') }
            maxLength={50}
            onBlur={handleBlurLieu}
            onFocus={handleFocusLieu}
          />
          
           {/* Afficher la liste de suggestions en tant que pop-up */}
          {isModalOpen && suggestions.length > 0 && (
            <AutoCompleteList
              suggestions={suggestions}
              onSelect={handleSelectSuggestion}
            />
          )}
        </div>
        { isMap && displayName !== "" && (editedLatitude1 !== "" && editedLongitude1 !== "") && (
          <div className="mt-2">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLoc"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={onLocalisationClick}
                              ></i>
                              <Tooltip id="myTooltipLoc" style={{ zIndex: 9999 }}></Tooltip>
          </div>
          )}
        
        { isMap && displayName !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
        <div>
          <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                              data-tooltip-content={t('cliquezPlacement')}
                              data-tooltip-place="top"
                              onClick={onPlacementClick}
                             ></i>
                             <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
        </div>
        )}
         { !isMap && displayName !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
        <div>
          <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                              data-tooltip-content={t('coordonneesGPSAbsentes')}
                              data-tooltip-place="top"
                             ></i>
                             <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
        </div>
        )}
        </div>
        <TextareaAutogrow
          maxLength={300}
          className="mt-1.5 text-xs text-center"
          defaultValue={editedActivites}
          placeholder={type === "" ? t('activitesVisites') : t('descriptionRepas')}
          onBlur={(event) => handleBlurTextarea(event, "activites")}
          onFocus={(event) => handleFocusTextarea(event)}
        />
      </div>
      { !isMap && (
        <div className="py-2 px-3 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
          <div><input
            type="text"
            defaultValue={editedDisplayPrix}
            className="text-xs font-medium text-center"
            style={{ maxWidth: '100px' }} 
            maxLength={11}
            onBlur={(event) => handleBlurPrix(event, "prix_activites")}
            onFocus={(event) => handleFocusPrix(event)}
            placeholder={t('prix')}
          />
          </div>
          <div>
          {CurrencyIcon}
          </div>
          <div>
          { editedDisplayPrix !== '' && Number(editedDisplayPrix) !== 0 && (
           < CheckboxSmall label={t('paye?')} name="paye" 
            onChange={(checked) => handleChangeCheckbox(checked)} 
            checked={editedPaiement === 'paye'} />
          )}
           </div>
        </div>
      )}

        {isModalPhotoOpen && (
          <ModalPhotoModif 
          editedPhoto={editedPhoto}
          editedPhotoLien={editedPhotoLien}
          nomEvenement={displayName}
          typeEvenement="lieu"
          idVoyage={idVoyage}
          idJournee={idJournee}
          index={index}
          id={id}
          majPhoto={onMajPhoto}
          majPhotoLien={onMajPhotoLien}
          onPhotoModifClose={closeModalPhoto}
          onDisplayElementChange={onDisplayElementChange}
          />
      /*  <Transition appear show={isModalPhotoOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalPhoto}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification d'une Photo
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPhoto} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                        
                      { !editedPhoto && (
                      <>
                        <form encType="multipart/form-data">
                        <input
                          name='photo'
                          type="file"
                          className="cursor-pointer"
                          accept="image/jpeg, image/jpg"
                          onChange={handlePhotoChange}
                        />
                        </form>

                        <div className="text-xxs text-center mt-3">
                          .jpeg et jpg acceptés, 8 Mo max
                        </div>
                      </>
                        )}

                        {editedPhoto && (
                          <>
                                <img
                                  //className={`pointeur absolute inset-0 w-full h-full object-cover ${radius}`}
                                  src={`${editedPhoto}`}
                                  alt={displayName}
                                  //onClick={handleAvatarClick}
                                />
                                <ButtonPrimary className="mt-3 mb-5" onClick={handlePhotoDelete}
                                >Supprimer</ButtonPrimary>
                          </>
                        )}
                        
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>*/
      )}

    </div>

    
  );
};

const TextareaAutogrow: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 40)}px`; // Ajustez 60 en fonction de vos besoins pour définir une hauteur minimale
    }
  };

  useEffect(() => {
    handleInput();
  }, [props.value, props.defaultValue]); // Utilisation d'un tableau de dépendances vide pour que cela s'exécute une seule fois après le rendu initial

  useEffect(() => {

    // Si libelle2 est vide, définissez la hauteur initiale sur 2 lignes
    if (props.defaultValue === "" && textareaRef.current) {
      textareaRef.current.style.height = '40px'; // Ajustez la hauteur initiale selon vos besoins
    }
  }, [props.defaultValue]);
  
  return <Textarea ref={textareaRef} onInput={handleInput} {...props} />;
};


export default CardLieuModif;
